import { AuthchildGuard } from './core/@auth/authchild.guard';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./modules/website/website.module').then(m => m.WebsiteModule) },
  { path: 'user', loadChildren: () => import('./modules/login-module/login-module.module').then(m => m.LoginModuleModule) },
  { path: 'lead', loadChildren: () => import('./modules/lead-management/lead-management.module').then(m => m.LeadManagementModule) },
  { path: 'auth', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
